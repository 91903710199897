import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { CompanyLogo } from "./logos"

const Footer = () => (
  <footer>
    <div className="ellipse-1">
      <svg
        width="295"
        height="542"
        viewBox="0 0 295 542"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.2"
          d="M523 271C523 406.603 411.779 517 274 517C136.221 517 25 406.603 25 271C25 135.397 136.221 25 274 25C411.779 25 523 135.397 523 271Z"
          stroke="#FF5E49"
          stroke-width="50"
        />
      </svg>
    </div>
    <div className="ellipse-2">
      <svg
        width="132"
        height="260"
        viewBox="0 0 132 260"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M-119 130C-119 194.431 -65.9097 247 0 247C65.9097 247 119 194.431 119 130C119 65.5691 65.9097 13 0 13C-65.9097 13 -119 65.5691 -119 130Z"
          stroke="#FF5E49"
          stroke-width="26"
        />
      </svg>
    </div>
    <Container className="gx-4">
      <Row>
        <Col xs={12} lg={1} className="company-logo">
          <CompanyLogo variant="light" withoutText={true} width={50} />
        </Col>
        <Col xs={4} md={3} lg={2}>
          <h4>Community</h4>
          {/*<a*/}
          {/*  href="https://twitter.com/Frac_rock"*/}
          {/*  target="_blank"*/}
          {/*  rel="noreferrer"*/}
          {/*>*/}
          {/*  Twitter*/}
          {/*</a>*/}
          <a
              href="https://discord.gg/fWRs9DvHAp"
              target="_blank"
              rel="noreferrer"
          >
            Discord
          </a>
          {/*<a*/}
          {/*    href="https://t.me/fractionalrocket"*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*>*/}
          {/*  Telegram*/}
          {/*</a>*/}

          {/*<Link to="/">Documentation</Link>*/}
          {/*<Link to="/">Chart</Link>*/}
        </Col>
        <Col xs={4} md={3} lg={2}>
          <h4>Docs</h4>
          <a href="https://docs.fractionalrocket.money" target="_blank" rel="noreferrer">
            GitBook
          </a>
          <a href="https://medium.com/@fr0ck" target="_blank" rel="noreferrer">
            Medium
          </a>
        </Col>
        <Col xs={4} md={3} lg={2}>
          <h4>Resources</h4>
          {/*<a*/}
          {/*    href="https://calc.fractionalrocket.money"*/}
          {/*    target="_blank"*/}
          {/*    rel="noreferrer"*/}
          {/*>Launch Calculator*/}
          {/*</a>*/}

          <a
              href="https://app.fractionalrocket.money"
              target="_blank"
              rel="noreferrer"
          >Investor dAPP
          </a>
          {/*<a href="https://discord.com" target="_blank">*/}
          {/*  Discord*/}
          {/*</a>*/}
        </Col>
        <Col xs={12} lg={{ span: 10, offset: 2 }} className="social-media">
          {/*<h4>Follow Us</h4>*/}
          {/*<span className="social-media-icon"></span>*/}
          {/*<span className="social-media-icon"></span>*/}
          {/*<span className="social-media-icon"></span>*/}
          {/*<span className="social-media-icon"></span>*/}
          {/*<span className="social-media-icon"></span>*/}
          {/*<span className="social-media-icon"></span>*/}
        </Col>
        <Col xs={12} lg={{ span: 10, offset: 1 }} className="copy">
          <span>® Fractional Rocket 2023</span><br />
          <span className="small">All calculators and information on this site are not financial advice, no rights can be derived.</span>
          {/*<Link to="/">Terms</Link>*/}
          {/*<Link to="/">Privacy Policy</Link>*/}
        </Col>
      </Row>
    </Container>
  </footer>
)

export default Footer

import * as React from "react"

export const CloseButton = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="26.7856"
      width="37.8806"
      height="4.54567"
      rx="2.27284"
      transform="rotate(-45 0 26.7856)"
      fill="#CB2D3E"
    />
    <rect
      x="3.21484"
      width="37.8806"
      height="4.54567"
      rx="2.27284"
      transform="rotate(45 3.21484 0)"
      fill="#CB2D3E"
    />
  </svg>
)

import * as React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import clsx from "clsx"
import { CompanyLogo } from "./logos"
import {
  PrimaryLinkButton,
  LightLinkButton,
  HamburgerButton,
  CloseButton,
} from "./buttons"
import NotificationBar from "./notification-bar"

const navs = [
  { to: "/#home", text: "Home", internal: true },
  // { to: "/#how-it-works", text: "How It Works", internal: true },
  // { to: "/#roadmap", text: "Roadmap", internal: true },
]

const OpenAppButton = ({ colorChange }) => (
  <a
    href="https://app.fractionalrocket.money/"
    className={`button ${
      colorChange ? "button-primary" : "button-light"
    } button-round`}
    target="_blank"
    rel="noopener noreferrer"
  >
    <CompanyLogo
      variant={colorChange ? "light" : "dark"}
      width={20}
      height={20}
      withoutText
    />
    <span style={{ marginLeft: "8px" }}>Open App</span>
  </a>
)

const calculateTimeLeft = () => {
  const now = new Date()
  const difference =
    Date.UTC(2022, 1, 19, 16) -
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    )

  if (difference <= 0) {
    return { isAfterTwoDays: difference <= -165600000 }
  }

  return {
    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
    hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((difference / 1000 / 60) % 60),
    seconds: Math.floor((difference / 1000) % 60),
  }
}

const Header = () => {
  const [colorChange, setColorchange] = React.useState(false)
  const [isHamburgerActive, setIsHamburgerActive] = React.useState(false)
  const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft())

  React.useEffect(() => {
    const id = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => {
      clearInterval(id)
    }
  }, [])

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true)
    } else {
      setColorchange(false)
    }
  }

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", changeNavbarColor)
    }
  }, [])

  const setOverflowHiddenOnBody = isHidden => {
    if (typeof document !== "undefined") {
      document.body.className = isHidden ? "overflow-hidden" : ""
    }
  }

  React.useEffect(() => {
    if (isHamburgerActive) {
      setColorchange(true)
      setOverflowHiddenOnBody(true)
    } else {
      changeNavbarColor()
      setOverflowHiddenOnBody(false)
    }
  }, [isHamburgerActive])

  return (
    <header
      className={clsx(
        colorChange && "light",
        isHamburgerActive && "nav-opened"
      )}
    >
      <Container className="gx-4">
        <CompanyLogo variant={colorChange ? "dark" : "light"} />
        <div
          className="hamburger"
          role="button"
          tabIndex={0}
          onClick={() => setIsHamburgerActive(!isHamburgerActive)}
          onKeyDown={() => setIsHamburgerActive(!isHamburgerActive)}
        >
          {isHamburgerActive ? (
            <CloseButton />
          ) : (
            <HamburgerButton colorChange={colorChange} />
          )}
        </div>
        <nav>
          {navs.map((nav, i) => (
            <>
              {nav.internal === true ? (
                <Link
                  key={i}
                  to={nav.to}
                  className={colorChange ? "text-dark" : "text-white"}
                >
                  {nav.text}
                </Link>
              ) : (
                <a
                  key={i}
                  href={nav.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={colorChange ? "text-dark" : "text-white"}
                >
                  {nav.text}
                </a>
              )}
            </>
          ))}
          <OpenAppButton colorChange={colorChange} />
        </nav>
      </Container>
      {isHamburgerActive && (
        <Container className="mobile">
          <a
            style={{ background: "#cb2d3e", border: "#cb2d3e", color: "#fff" }}
            href="https://app.fractionalrocket.money/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CompanyLogo variant="light" width={20} height={20} withoutText />
            <span style={{ marginLeft: "8px" }}>Open App</span>
          </a>
          {navs.map((nav, i) => (
            <>
              {nav.internal === true ? (
                <Link key={i} to={nav.to}>
                  {nav.text}
                </Link>
              ) : (
                <a
                  key={i}
                  href={nav.to}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {nav.text}
                </a>
              )}
            </>
          ))}
        </Container>
      )}
    </header>
  )
}

export default Header

import * as React from "react"

export const HamburgerButton = ({ colorChange }) => (
  <svg
    width="50"
    height="34"
    viewBox="0 0 50 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="50"
      height="6"
      rx="3"
      fill={colorChange ? "#CB2D3E" : "white"}
    />
    <rect
      y="14"
      width="50"
      height="6"
      rx="3"
      fill={colorChange ? "#CB2D3E" : "white"}
    />
    <rect
      y="28"
      width="50"
      height="6"
      rx="3"
      fill={colorChange ? "#CB2D3E" : "white"}
    />
  </svg>
)

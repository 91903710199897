import * as React from "react"

export const CompanyLogo = ({ withoutText, variant, width, height }) => {
  const textColor = variant === "light" ? "white" : "black"

  if (withoutText) {
    if (variant === "dark") {
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M43.3731 6.62682C34.5373 -2.20894 20.1922 -2.20894 11.3564 6.62682C5.19739 12.7859 3.35228 21.6476 5.79511 29.4439L11.6683 27.4948C9.92712 21.8295 11.2785 15.4366 15.7483 10.9927C22.1672 4.5738 32.5882 4.5738 39.0072 10.9927C45.4261 17.4116 45.4261 27.8326 39.0072 34.2516C34.5633 38.6954 28.1704 40.0728 22.5311 38.3316L20.582 44.2048C28.3783 46.6476 37.24 44.8025 43.3991 38.6175C52.2088 29.8077 52.2088 15.4366 43.3731 6.62682Z"
            fill="url(#paint0_linear_61_3563)"
          />
          <path
            d="M25.5195 22.8431L11.6422 27.4689L5.76903 29.4179L0.857388 31.0551C0.155724 31.289 -0.208101 32.0427 0.0517743 32.7183C0.597512 34.1477 2.46861 36.5645 4.80749 39.1633C4.88545 39.2672 4.9894 39.3712 5.09335 39.4751C5.09335 39.4751 5.09335 39.4751 5.11934 39.5011C6.28878 40.6705 8.02994 40.9564 9.48525 40.3327C9.74512 40.2287 10.005 40.4886 9.90105 40.7485C9.30333 42.1258 9.53722 43.763 10.6027 44.9325C10.6547 44.9845 10.7067 45.0364 10.7586 45.1144C10.9146 45.2703 11.0705 45.4003 11.2524 45.5302C13.6952 47.7131 15.9302 49.4023 17.2555 49.8961C17.9312 50.156 18.7108 49.7922 18.9187 49.0905L20.5559 44.1788L22.505 38.3057L27.1308 24.4283C27.4686 23.4668 26.5331 22.5312 25.5195 22.8431ZM17.8013 36.3306C16.6578 37.4741 14.7867 37.4741 13.6432 36.3306C12.4998 35.1872 12.4998 33.3161 13.6432 32.1726C13.6952 32.1206 13.7732 32.0427 13.8511 31.9907C14.9946 31.0292 16.7358 31.0811 17.8013 32.1726C18.8927 33.2641 18.9447 34.9793 17.9832 36.1227C17.9312 36.2007 17.8792 36.2786 17.8013 36.3306Z"
            fill="url(#paint1_linear_61_3563)"
          />
          <path
            d="M13.6433 32.1727C13.6952 32.1207 13.7732 32.0427 13.8512 31.9908C14.9946 31.0292 16.7358 31.0812 17.8013 32.1727L26.8189 23.155C26.5071 22.8432 26.0133 22.6872 25.5196 22.8432L11.6422 27.4689L5.76903 29.418L0.857388 31.0552C0.155724 31.2891 -0.208101 32.0427 0.0517743 32.7184C0.597512 34.1477 2.46861 36.5646 4.80749 39.1633C4.88546 39.2673 4.98941 39.3712 5.09336 39.4752C5.09336 39.4752 5.09336 39.4752 5.11934 39.5012C6.28878 40.6706 8.02995 40.9565 9.48525 40.3328C9.53722 40.3068 9.61518 40.3068 9.66716 40.3068L13.6433 36.3307C12.4998 35.1872 12.4998 33.3421 13.6433 32.1727Z"
            fill="url(#paint2_linear_61_3563)"
          />
          <path
            d="M13.6433 32.1727C13.6952 32.1207 13.7732 32.0427 13.8512 31.9908C14.9946 31.0292 16.7358 31.0812 17.8013 32.1727L26.8189 23.155C26.5071 22.8432 26.0133 22.6872 25.5196 22.8432L11.6422 27.4689L5.76903 29.418L0.857388 31.0552C0.155724 31.2891 -0.208101 32.0427 0.0517743 32.7184C0.597512 34.1477 2.46861 36.5646 4.80749 39.1633C4.88546 39.2673 4.98941 39.3712 5.09336 39.4752C5.09336 39.4752 5.09336 39.4752 5.11934 39.5012C6.28878 40.6706 8.02995 40.9565 9.48525 40.3328C9.53722 40.3068 9.61518 40.3068 9.66716 40.3068L13.6433 36.3307C12.4998 35.1872 12.4998 33.3421 13.6433 32.1727Z"
            fill="url(#paint3_linear_61_3563)"
          />
          <path
            d="M11.3564 6.6269C5.19739 12.7859 3.35228 21.6477 5.79511 29.4439L11.6683 27.4949C9.92712 21.8296 11.2785 15.4367 15.7483 10.9928C22.1672 4.57388 32.5882 4.57388 38.9812 10.9668L43.3471 6.60091C34.5373 -2.20886 20.1922 -2.20886 11.3564 6.6269Z"
            fill="url(#paint4_linear_61_3563)"
          />
          <path
            d="M6.57485 43.2173L0 50H3.92412C6.08108 50 7.84824 48.2589 7.84824 46.0759C7.82225 44.9325 7.32848 43.9189 6.57485 43.2173Z"
            fill="url(#paint5_linear_61_3563)"
          />
          <path
            d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
            fill="url(#paint6_linear_61_3563)"
          />
          <path
            d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
            fill="url(#paint7_linear_61_3563)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_61_3563"
              x1="44.7722"
              y1="5.22152"
              x2="0.569317"
              y2="49.4243"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_61_3563"
              x1="62.4633"
              y1="-13.0929"
              x2="-38.2942"
              y2="88.85"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.3827" stopColor="#EF473A" />
              <stop offset="0.8792" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_61_3563"
              x1="-3.30433"
              y1="12.604"
              x2="30.7749"
              y2="45.6967"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.3827" stopColor="#EF473A" />
              <stop offset="0.8792" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_61_3563"
              x1="11.9269"
              y1="26.8782"
              x2="23.5162"
              y2="39.6176"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_61_3563"
              x1="14.1706"
              y1="2.24826"
              x2="35.7568"
              y2="34.9815"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_61_3563"
              x1="-0.0556395"
              y1="52.5083"
              x2="8.22082"
              y2="44.4802"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.4193" stopColor="#EF473A" />
              <stop offset="1" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_61_3563"
              x1="-1.51749"
              y1="40.9109"
              x2="4.44155"
              y2="47.9734"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.4413" stopColor="#EF473A" />
              <stop offset="1" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_61_3563"
              x1="-3.37768"
              y1="41.5054"
              x2="7.83565"
              y2="50.0647"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
          </defs>
        </svg>
      )
    }

    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 51 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.3947 6.60606C34.5866 -2.20202 20.2028 -2.20202 11.3947 6.60606C5.25326 12.7475 3.39467 21.6364 5.81892 29.4747L0.889624 31.0909C0.162351 31.3333 -0.160881 32.0606 0.0815433 32.7879C0.6472 34.2424 2.50579 36.6667 4.84922 39.2525C4.93003 39.3333 5.01084 39.4949 5.17245 39.5758C6.38457 40.7071 8.08154 41.0303 9.53609 40.3838C9.77851 40.303 10.0209 40.5455 9.94013 40.7879C9.37447 42.1616 9.6169 43.7778 10.6674 44.9899C10.7482 45.0707 10.7482 45.0707 10.829 45.1515C10.9906 45.3131 11.1523 45.4747 11.3139 45.5556C13.7381 47.7374 16.0007 49.4343 17.2937 49.9192C17.9401 50.1616 18.7482 49.8384 18.9906 49.1111L20.6068 44.1818C28.4452 46.6061 37.2533 44.7475 43.4755 38.6061C52.2836 29.798 52.2836 15.4141 43.3947 6.60606ZM13.6573 32.2222C13.7381 32.1414 13.8189 32.0606 13.8189 32.0606C14.9502 31.0909 16.728 31.1717 17.7785 32.2222C18.829 33.2727 18.9098 35.0505 17.9401 36.1818C17.8593 36.2626 17.8593 36.3434 17.7785 36.3434C16.6472 37.4747 14.7886 37.4747 13.5765 36.3434C12.526 35.2121 12.526 33.3535 13.6573 32.2222ZM39.031 34.2424C34.5866 38.6869 28.2028 40.0606 22.5462 38.3636L27.1522 24.4646C27.4755 23.4949 26.5058 22.5253 25.5361 22.8485L11.6371 27.4545C9.94013 21.798 11.2331 15.4141 15.7583 10.9697C22.1421 4.58586 32.6472 4.58586 39.031 10.9697C45.4957 17.4343 45.4957 27.8586 39.031 34.2424Z"
          fill="white"
        />
        <path
          d="M3.87879 50H0V46.1212C0 43.9394 1.77778 42.2424 3.87879 42.2424C6.06061 42.2424 7.75758 44.0202 7.75758 46.1212C7.83838 48.303 6.06061 50 3.87879 50Z"
          fill="white"
        />
      </svg>
    )
  }

  return (
    <svg
      width="183"
      height="50"
      viewBox="0 0 183 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {variant === "light" ? (
        <>
          <path
            d="M43.3947 6.60606C34.5866 -2.20202 20.2028 -2.20202 11.3947 6.60606C5.25326 12.7475 3.39467 21.6364 5.81892 29.4747L0.889624 31.0909C0.162351 31.3333 -0.160881 32.0606 0.0815433 32.7879C0.6472 34.2424 2.50579 36.6667 4.84922 39.2525C4.93003 39.3333 5.01084 39.4949 5.17245 39.5758C6.38457 40.7071 8.08154 41.0303 9.53609 40.3838C9.77851 40.303 10.0209 40.5455 9.94013 40.7879C9.37447 42.1616 9.6169 43.7778 10.6674 44.9899C10.7482 45.0707 10.7482 45.0707 10.829 45.1515C10.9906 45.3131 11.1523 45.4747 11.3139 45.5556C13.7381 47.7374 16.0007 49.4343 17.2937 49.9192C17.9401 50.1616 18.7482 49.8384 18.9906 49.1111L20.6068 44.1818C28.4452 46.6061 37.2533 44.7475 43.4755 38.6061C52.2836 29.798 52.2836 15.4141 43.3947 6.60606ZM13.6573 32.2222C13.7381 32.1414 13.8189 32.0606 13.8189 32.0606C14.9502 31.0909 16.728 31.1717 17.7785 32.2222C18.829 33.2727 18.9098 35.0505 17.9401 36.1818C17.8593 36.2626 17.8593 36.3434 17.7785 36.3434C16.6472 37.4747 14.7886 37.4747 13.5765 36.3434C12.526 35.2121 12.526 33.3535 13.6573 32.2222ZM39.031 34.2424C34.5866 38.6869 28.2028 40.0606 22.5462 38.3636L27.1522 24.4646C27.4755 23.4949 26.5058 22.5253 25.5361 22.8485L11.6371 27.4545C9.94013 21.798 11.2331 15.4141 15.7583 10.9697C22.1421 4.58586 32.6472 4.58586 39.031 10.9697C45.4957 17.4343 45.4957 27.8586 39.031 34.2424Z"
            fill="white"
          />
          <path
            d="M3.87879 50H0V46.1212C0 43.9394 1.77778 42.2424 3.87879 42.2424C6.06061 42.2424 7.75758 44.0202 7.75758 46.1212C7.83838 48.303 6.06061 50 3.87879 50Z"
            fill="white"
          />
        </>
      ) : (
        <>
          <path
            d="M43.3731 6.62682C34.5373 -2.20894 20.1922 -2.20894 11.3564 6.62682C5.19739 12.7859 3.35228 21.6476 5.79511 29.4439L11.6683 27.4948C9.92712 21.8295 11.2785 15.4366 15.7483 10.9927C22.1672 4.5738 32.5882 4.5738 39.0072 10.9927C45.4261 17.4116 45.4261 27.8326 39.0072 34.2516C34.5633 38.6954 28.1704 40.0728 22.5311 38.3316L20.582 44.2048C28.3783 46.6476 37.24 44.8025 43.3991 38.6175C52.2088 29.8077 52.2088 15.4366 43.3731 6.62682Z"
            fill="url(#paint0_linear_61_3563)"
          />
          <path
            d="M25.5195 22.8431L11.6422 27.4689L5.76903 29.4179L0.857388 31.0551C0.155724 31.289 -0.208101 32.0427 0.0517743 32.7183C0.597512 34.1477 2.46861 36.5645 4.80749 39.1633C4.88545 39.2672 4.9894 39.3712 5.09335 39.4751C5.09335 39.4751 5.09335 39.4751 5.11934 39.5011C6.28878 40.6705 8.02994 40.9564 9.48525 40.3327C9.74512 40.2287 10.005 40.4886 9.90105 40.7485C9.30333 42.1258 9.53722 43.763 10.6027 44.9325C10.6547 44.9845 10.7067 45.0364 10.7586 45.1144C10.9146 45.2703 11.0705 45.4003 11.2524 45.5302C13.6952 47.7131 15.9302 49.4023 17.2555 49.8961C17.9312 50.156 18.7108 49.7922 18.9187 49.0905L20.5559 44.1788L22.505 38.3057L27.1308 24.4283C27.4686 23.4668 26.5331 22.5312 25.5195 22.8431ZM17.8013 36.3306C16.6578 37.4741 14.7867 37.4741 13.6432 36.3306C12.4998 35.1872 12.4998 33.3161 13.6432 32.1726C13.6952 32.1206 13.7732 32.0427 13.8511 31.9907C14.9946 31.0292 16.7358 31.0811 17.8013 32.1726C18.8927 33.2641 18.9447 34.9793 17.9832 36.1227C17.9312 36.2007 17.8792 36.2786 17.8013 36.3306Z"
            fill="url(#paint1_linear_61_3563)"
          />
          <path
            d="M13.6433 32.1727C13.6952 32.1207 13.7732 32.0427 13.8512 31.9908C14.9946 31.0292 16.7358 31.0812 17.8013 32.1727L26.8189 23.155C26.5071 22.8432 26.0133 22.6872 25.5196 22.8432L11.6422 27.4689L5.76903 29.418L0.857388 31.0552C0.155724 31.2891 -0.208101 32.0427 0.0517743 32.7184C0.597512 34.1477 2.46861 36.5646 4.80749 39.1633C4.88545 39.2673 4.98941 39.3712 5.09336 39.4752C5.09336 39.4752 5.09336 39.4752 5.11934 39.5012C6.28878 40.6706 8.02995 40.9565 9.48525 40.3328C9.53722 40.3068 9.61519 40.3068 9.66716 40.3068L13.6433 36.3307C12.4998 35.1872 12.4998 33.3421 13.6433 32.1727Z"
            fill="url(#paint2_linear_61_3563)"
          />
          <path
            d="M13.6433 32.1727C13.6952 32.1207 13.7732 32.0427 13.8512 31.9908C14.9946 31.0292 16.7358 31.0812 17.8013 32.1727L26.8189 23.155C26.5071 22.8432 26.0133 22.6872 25.5196 22.8432L11.6422 27.4689L5.76903 29.418L0.857388 31.0552C0.155724 31.2891 -0.208101 32.0427 0.0517743 32.7184C0.597512 34.1477 2.46861 36.5646 4.80749 39.1633C4.88545 39.2673 4.98941 39.3712 5.09336 39.4752C5.09336 39.4752 5.09336 39.4752 5.11934 39.5012C6.28878 40.6706 8.02995 40.9565 9.48525 40.3328C9.53722 40.3068 9.61519 40.3068 9.66716 40.3068L13.6433 36.3307C12.4998 35.1872 12.4998 33.3421 13.6433 32.1727Z"
            fill="url(#paint3_linear_61_3563)"
          />
          <path
            d="M11.3564 6.6269C5.19739 12.7859 3.35228 21.6477 5.79511 29.4439L11.6683 27.4949C9.92712 21.8296 11.2785 15.4367 15.7483 10.9928C22.1672 4.57388 32.5882 4.57388 38.9812 10.9668L43.3471 6.60091C34.5373 -2.20886 20.1922 -2.20886 11.3564 6.6269Z"
            fill="url(#paint4_linear_61_3563)"
          />
          <path
            d="M6.57485 43.2173L0 50H3.92412C6.08108 50 7.84824 48.2589 7.84824 46.0759C7.82225 44.9325 7.32848 43.9189 6.57485 43.2173Z"
            fill="url(#paint5_linear_61_3563)"
          />
          <path
            d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
            fill="url(#paint6_linear_61_3563)"
          />
          <path
            d="M3.92412 42.1777C1.74116 42.1777 0 43.9189 0 46.0759V50L6.57485 43.2172C5.87318 42.5675 4.93763 42.1777 3.92412 42.1777Z"
            fill="url(#paint7_linear_61_3563)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_61_3563"
              x1="44.7722"
              y1="5.22152"
              x2="0.569317"
              y2="49.4243"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_61_3563"
              x1="62.4633"
              y1="-13.0929"
              x2="-38.2942"
              y2="88.85"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.3827" stopColor="#EF473A" />
              <stop offset="0.8792" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_61_3563"
              x1="-3.30433"
              y1="12.604"
              x2="30.7749"
              y2="45.6967"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.3827" stopColor="#EF473A" />
              <stop offset="0.8792" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_61_3563"
              x1="11.9269"
              y1="26.8782"
              x2="23.5162"
              y2="39.6176"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_61_3563"
              x1="14.1706"
              y1="2.24826"
              x2="35.7568"
              y2="34.9815"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_61_3563"
              x1="-0.0556395"
              y1="52.5083"
              x2="8.22082"
              y2="44.4802"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.4193" stopColor="#EF473A" />
              <stop offset="1" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_61_3563"
              x1="-1.51749"
              y1="40.9109"
              x2="4.44155"
              y2="47.9734"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.4413" stopColor="#EF473A" />
              <stop offset="1" stopColor="#CB2D3E" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_61_3563"
              x1="-3.37768"
              y1="41.5054"
              x2="7.83565"
              y2="50.0647"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#EF473A" />
              <stop offset="0.7414" stopColor="#CB2D3E" />
            </linearGradient>
          </defs>
        </>
      )}
      <path
        d="M66.0713 18.1413V0.929199H77.546V4.16152H69.4652V8.04031H76.4955V11.2726H69.4652V18.1413H66.0713Z"
        fill={textColor}
      />
      <path
        d="M79.0811 18.1414V5.45458H82.2326V7.15155C83.0407 5.77781 84.172 5.13135 85.7073 5.13135C86.1922 5.13135 86.7578 5.21216 87.2427 5.37377L87.0002 8.52529C86.4346 8.36367 85.9497 8.28286 85.4649 8.28286C83.6063 8.28286 82.3942 9.49498 82.3942 12.1617V18.1414H79.0811Z"
        fill={textColor}
      />
      <path
        d="M97.9098 5.45458H101.061V18.1414H97.9098V16.6869C96.7785 17.899 95.4856 18.4647 93.8694 18.4647C92.0109 18.4647 90.5563 17.8182 89.3442 16.5253C88.1321 15.2324 87.5664 13.6162 87.5664 11.7576C87.5664 9.89902 88.1321 8.28286 89.3442 6.98993C90.5563 5.697 92.0109 5.13135 93.8694 5.13135C95.4856 5.13135 96.7785 5.77781 97.9098 6.98993V5.45458ZM96.9401 9.17175C96.2937 8.44448 95.4048 8.12125 94.4351 8.12125C93.3846 8.12125 92.5765 8.44448 91.8492 9.17175C91.2028 9.89902 90.8795 10.7879 90.8795 11.7576C90.8795 12.8081 91.2028 13.6162 91.8492 14.3435C92.4957 15.0707 93.3846 15.394 94.3543 15.394C95.4048 15.394 96.2129 15.0707 96.8593 14.3435C97.5058 13.6162 97.9098 12.7273 97.9098 11.7576C97.9907 10.7879 97.5866 9.89902 96.9401 9.17175Z"
        fill={textColor}
      />
      <path
        d="M104.94 16.5253C103.647 15.3132 103.081 13.697 103.081 11.7576C103.081 9.81822 103.728 8.20205 105.02 6.98993C106.313 5.697 107.93 5.13135 109.95 5.13135C112.536 5.13135 114.798 6.42428 115.849 8.6869L113.182 10.1414C112.374 8.84852 111.323 8.20206 109.95 8.20206C108.899 8.20206 108.01 8.52529 107.364 9.25256C106.717 9.89902 106.313 10.7879 106.313 11.8384C106.313 12.8889 106.637 13.7778 107.283 14.4243C107.93 15.0707 108.818 15.4748 109.788 15.4748C111.081 15.4748 112.455 14.6667 113.02 13.5354L115.687 15.0707C114.556 17.0909 112.293 18.4647 109.707 18.4647C107.849 18.3839 106.233 17.8182 104.94 16.5253Z"
        fill={textColor}
      />
      <path
        d="M126.759 17.414C125.708 18.1413 124.496 18.4646 123.042 18.4646C120.132 18.4646 118.597 16.7676 118.597 13.4545V8.20193H116.496V5.45445H118.597V0.929199H121.829V5.45445H126.031V8.20193H121.829V13.212C121.829 14.6666 122.395 15.4747 123.607 15.4747C124.254 15.4747 124.981 15.2322 125.708 14.8282L126.759 17.414Z"
        fill={textColor}
      />
      <path
        d="M128.374 3.43434C127.566 2.62626 127.566 1.33333 128.374 0.606061C129.182 -0.20202 130.475 -0.20202 131.283 0.606061C132.091 1.33333 132.091 2.70707 131.283 3.43434C130.475 4.16162 129.101 4.16162 128.374 3.43434ZM131.444 18.1414H128.131V5.45455H131.444V18.1414Z"
        fill={textColor}
      />
      <path
        d="M135.405 7.07074C136.698 5.77781 138.314 5.13135 140.335 5.13135C142.355 5.13135 143.971 5.77781 145.264 7.07074C146.557 8.36367 147.203 9.89902 147.203 11.8384C147.203 13.697 146.557 15.3132 145.264 16.6061C143.971 17.899 142.355 18.5455 140.335 18.5455C138.314 18.5455 136.698 17.899 135.405 16.6061C134.112 15.3132 133.466 13.7778 133.466 11.8384C133.466 9.89902 134.112 8.28286 135.405 7.07074ZM140.254 15.394C141.223 15.394 142.112 15.0707 142.759 14.3435C143.405 13.6162 143.809 12.7273 143.809 11.7576C143.809 10.7879 143.486 9.89902 142.759 9.17175C142.112 8.44448 141.304 8.12125 140.254 8.12125C139.284 8.12125 138.395 8.44448 137.749 9.17175C137.102 9.89902 136.779 10.7071 136.779 11.7576C136.779 12.8081 137.102 13.6162 137.749 14.3435C138.395 15.0707 139.284 15.394 140.254 15.394Z"
        fill={textColor}
      />
      <path
        d="M149.142 18.1414V5.45458H152.374V7.23236C153.02 6.02024 154.556 5.13135 156.334 5.13135C157.707 5.13135 158.919 5.6162 159.889 6.50509C160.859 7.39397 161.344 8.6869 161.344 10.3839V18.1414H158.03V11.2728C158.03 9.25256 156.98 8.12125 155.364 8.12125C153.586 8.12125 152.455 9.41418 152.455 11.3536V18.1414H149.142Z"
        fill={textColor}
      />
      <path
        d="M173.547 18.1414V16.6869C172.415 17.899 171.122 18.4647 169.506 18.4647C167.648 18.4647 166.193 17.8182 164.981 16.5253C163.769 15.2324 163.203 13.6162 163.203 11.7576C163.203 9.89902 163.769 8.28286 164.981 6.98993C166.193 5.697 167.648 5.13135 169.506 5.13135C171.122 5.13135 172.415 5.77781 173.547 6.98993V5.45458H176.698V18.1414H173.547ZM172.577 9.17175C171.93 8.44448 171.042 8.12125 170.072 8.12125C169.021 8.12125 168.213 8.44448 167.486 9.17175C166.839 9.89902 166.516 10.7879 166.516 11.7576C166.516 12.8081 166.839 13.6162 167.486 14.3435C168.132 15.0707 169.021 15.394 169.991 15.394C171.042 15.394 171.85 15.0707 172.496 14.3435C173.143 13.6162 173.547 12.7273 173.547 11.7576C173.627 10.7879 173.304 9.89902 172.577 9.17175Z"
        fill={textColor}
      />
      <path
        d="M182.758 18.1416H179.445V0.44458H182.758V18.1416Z"
        fill={textColor}
      />
      <path
        d="M75.2026 39.8791L71.5662 33.5761H69.546V39.8791H66.0713V22.667H72.6975C74.6369 22.667 76.0915 23.2326 77.0612 24.2832C78.0309 25.3337 78.5965 26.6266 78.5965 28.1619C78.5965 30.4246 77.4652 32.364 75.1218 33.2529L79.3238 39.9599H75.2026V39.8791ZM69.4652 25.8993V30.5862H72.2935C74.0713 30.5862 74.9602 29.5357 74.9602 28.2428C74.9602 26.9498 74.0713 25.8993 72.6167 25.8993H69.4652Z"
        fill={textColor}
      />
      <path
        d="M81.9101 28.8085C83.203 27.5156 84.8192 26.8691 86.8394 26.8691C88.8596 26.8691 90.4758 27.5156 91.7687 28.8085C93.0616 30.1015 93.7081 31.6368 93.7081 33.5762C93.7081 35.4348 93.0616 37.051 91.7687 38.3439C90.4758 39.6368 88.8596 40.2833 86.8394 40.2833C84.8192 40.2833 83.203 39.6368 81.9101 38.3439C80.6172 37.051 79.9707 35.5156 79.9707 33.5762C80.0515 31.7176 80.698 30.1015 81.9101 28.8085ZM86.8394 37.2126C87.8091 37.2126 88.698 36.8893 89.3444 36.1621C89.9909 35.4348 90.3949 34.5459 90.3949 33.5762C90.3949 32.6065 90.0717 31.7176 89.3444 30.9904C88.698 30.2631 87.8899 29.9398 86.8394 29.9398C85.7889 29.9398 84.9808 30.2631 84.3343 30.9904C83.6879 31.7176 83.3646 32.5257 83.3646 33.5762C83.3646 34.6267 83.6879 35.4348 84.3343 36.1621C84.9808 36.8085 85.8697 37.2126 86.8394 37.2126Z"
        fill={textColor}
      />
      <path
        d="M96.9406 38.3436C95.6477 37.1315 95.082 35.5154 95.082 33.576C95.082 31.6366 95.7285 30.0204 97.0214 28.8083C98.3143 27.5154 99.9305 26.9497 101.951 26.9497C104.537 26.9497 106.799 28.2426 107.85 30.5053L105.183 31.9598C104.375 30.6669 103.324 30.0204 101.951 30.0204C100.9 30.0204 100.011 30.3436 99.3649 31.0709C98.7184 31.7174 98.3144 32.6063 98.3144 33.6568C98.3144 34.7073 98.6376 35.5962 99.284 36.2426C99.9305 36.8891 100.819 37.2931 101.789 37.2931C103.082 37.2931 104.456 36.4851 105.021 35.3537L107.688 36.8891C106.557 38.9093 104.294 40.283 101.708 40.283C99.7689 40.2022 98.1527 39.5558 96.9406 38.3436Z"
        fill={textColor}
      />
      <path
        d="M117.87 39.8791L113.668 34.465L112.779 35.273V39.9599H109.466V22.1821H112.779V31.5559L117.385 27.1922H121.506L115.85 32.3639L121.829 39.8791H117.87Z"
        fill={textColor}
      />
      <path
        d="M134.193 34.7073H124.658C125.143 36.4851 126.355 37.3739 128.375 37.3739C129.749 37.3739 131.365 36.8083 132.496 36.081L133.789 38.4245C132.173 39.5558 130.314 40.2022 128.213 40.2022C125.951 40.2022 124.254 39.5558 123.123 38.2628C121.91 36.9699 121.345 35.4346 121.345 33.576C121.345 31.6366 121.91 30.0204 123.203 28.8083C124.415 27.5154 126.032 26.9497 127.971 26.9497C129.83 26.9497 131.365 27.5154 132.577 28.6467C133.789 29.778 134.355 31.3133 134.355 33.3335C134.355 33.7376 134.274 34.2224 134.193 34.7073ZM124.658 32.283H131.122C130.799 30.6669 129.668 29.6164 127.971 29.6164C126.355 29.6164 125.062 30.5861 124.658 32.283Z"
        fill={textColor}
      />
      <path
        d="M145.345 39.1518C144.294 39.8791 143.082 40.2023 141.627 40.2023C138.718 40.2023 137.183 38.5054 137.183 35.1922V29.9397H135.082V27.1922H137.183V22.667H140.415V27.1922H144.617V29.9397H140.415V34.9498C140.415 36.4044 140.981 37.2124 142.193 37.2124C142.84 37.2124 143.567 36.97 144.294 36.566L145.345 39.1518Z"
        fill={textColor}
      />
    </svg>
  )
}
